import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import img_1 from '../image/217.svg';
import img_2 from '../image/218.png';
import about_1 from '../image/about1.jpg';
import about_2 from '../image/about2.jpg';
import about_3 from '../image/about3.jpg';

import '../App.css'

function About() {
  return (
    <div className="about">
      <div className='header'>
        <div className='subPart1'>
          <Link to="/"><img className="img1" src={img_1}></img></Link>
        </div>
        <div className='subPart2'>
          <img className="img2" src={img_2}></img>  
        </div>
      </div>
      <div className='content'>
        <img className="about1" src={about_1}></img>
        <img className="about2" src={about_2}></img>
        <img className="about3" src={about_3}></img>
      </div>
    </div>

    
  );
}

export default About;
