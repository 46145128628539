import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import img_1 from '../image/39.png';
import img_2 from '../image/41.svg';
import img_3 from '../image/202.png';
import img_4 from '../image/203.png';
import img_5 from '../image/204.png';
import img_6 from '../image/205.png';
import img_7 from '../image/206.png';
import img_8 from '../image/207.png';
import img_9 from '../image/40.svg';
import img_10 from '../image/213.png';
import img_11 from '../image/196.png';
import img_12 from '../image/197.png';
import img_13 from '../image/215.svg';

import '../App.css';

function Home() {
  useEffect(() => {
    const interval = setInterval(() => {
      const grayDiv = document.querySelector('.main1.gray');
      const greenDiv = document.querySelector('.main1.green');

      if (greenDiv.classList.contains('active')) {
        greenDiv.classList.remove('display_none');
        grayDiv.classList.add('active');
        grayDiv.classList.remove('none');
        greenDiv.classList.remove('active');
        greenDiv.classList.add('none');
      } else {
        greenDiv.classList.remove('display_none');
        grayDiv.classList.remove('active');
        grayDiv.classList.add('none');
        greenDiv.classList.add('active');
        greenDiv.classList.remove('none');
      }
    }, 8000); // 8초마다 실행

    // 컴포넌트가 언마운트되면 인터벌 제거
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const imgElement = document.querySelector('.img8');

    if (imgElement) {
      const imgHeight = imgElement.clientHeight + 35;
      
      const sub4Elements = document.querySelectorAll('.sub4');
      sub4Elements.forEach((sub4Element) => {
        sub4Element.style.height = `${imgHeight}px`;
      });
    }
  }, []);

  useEffect(() => {
    const imgElement = document.querySelector('.img8');

    const applyHeightToSub4Elements = () => {
      if (imgElement) {
        const imgHeight = imgElement.clientHeight + 35;

        const sub4Elements = document.querySelectorAll('.sub4');
        sub4Elements.forEach((sub4Element) => {
          sub4Element.style.height = `${imgHeight}px`;
        });
      }
    };

    if (imgElement && imgElement.complete) {
      applyHeightToSub4Elements();
    }
    
    if (imgElement) {
      imgElement.addEventListener('load', applyHeightToSub4Elements);
    }

    window.addEventListener('resize', applyHeightToSub4Elements);

    return () => {
      if (imgElement) {
        imgElement.removeEventListener('load', applyHeightToSub4Elements);
      }

      window.removeEventListener('resize', applyHeightToSub4Elements);
    };
  }, []);

  return (
    <div className="home">
      <div className="main1 gray"> 
        <div className='header'>
          <div className='subPart1'>
            <img className="img2" src={img_2}></img>  
          </div>
          <div className='subPart2'>
            <img className="img7" src={img_7}></img>  
          </div>
        </div>
        <div className='content1'>
          <div className='subPart1 sub1'>
            <img className="img9" src={img_9}></img>
            <img className="img10" src={img_10}></img>
          </div>
          <div className='subPart2 sub2'>
            <Link to="/about"><img className="img5" src={img_5}></img></Link>
            <img className="img6" src={img_6}></img>
          </div>
        </div>
        <div className='content2'>
          <div className='subPart1 large sub3'>
            <img className="img8" src={img_8}></img>
          </div>
          <div className='subPart2 sub4'>
            <img className="img4" src={img_4}></img>
            <img className="img1" src={img_1}></img>
          </div>
        </div>
        <div className='footer'>
          <img className="img3" src={img_3}></img>
        </div>
      </div>

      <div className="main1 green none display_none"> 
        <div className='header'>
          <div className='subPart1'>
            <img className="img2" src={img_2}></img>  
          </div>
          <div className='subPart2'>
            <img className="img7" src={img_7}></img>  
          </div>
        </div>
        <div className='content1'>
          <div className='subPart1 sub1'>
            <img className="img9" src={img_13}></img>
            <img className="img10" src={img_10}></img>
          </div>
          <div className='subPart2 sub2'>
            <Link to="/about"><img className="img5" src={img_11}></img></Link>
            <img className="img6" src={img_12}></img>
          </div>
        </div>
        <div className='content2'>
          <div className='subPart1 large sub3'>
            <img className="img8" src={img_8}></img>
          </div>
          <div className='subPart2 sub4'>
            <img className="img4" src={img_4}></img>
            <img className="img1" src={img_1}></img>
          </div>
        </div>
        <div className='footer'>
          <img className="img3" src={img_3}></img>
        </div>
      </div>
    </div>

    
  );
}

export default Home;
